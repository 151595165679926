import Session from '@/handler/session'

var error = {
  showError (error) {        
    if (error.response == undefined) {
      return "Se encontraron errores en el proceso"
    }
    else {
      if(error.response.data == undefined) {
        return "Se encontraron errores en el proceso"
      } else {
        if(error.response.data.token_error == undefined) {
          if(error.response.data.message == undefined) {
            return error.response.data
          } else {
            return error.response.data.message
          }        
        } else {
          Session.removeSessionAuthTokenProblem()
          return error.response.data.message
        }
      }
    }
  }
}

export default error
