<template>
  <b-row>
    <b-col sm="12">
      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col sm="7">
            <h4 id="traffic" class="card-title mb-0">Cartera de Valores</h4>
            <div class="small text-muted">Administración de cheques en cartera</div>
          </b-col>
          
          <b-col sm="5">
            <b-button @click="add()" type="button" variant="primary" size="sm" class="btn-pill float-right ml-2" v-b-tooltip.hover title="Agregar Producto Compuesto">
              <i class="fa fa-plus"></i> Agregar
            </b-button>

            <b-col sm="12" class="d-none d-md-block" v-if="!table.mostrarFiltros">
              <b-button type="button" variant="outline-dark" size="sm" class="btn-pill float-right" @click="table.mostrarFiltros=true" v-b-tooltip.hover title="Mostrar filtros">
                <i class="fa fa-filter"></i> Mostrar Filtros
              </b-button>
            </b-col>
            <b-col sm="12" class="d-none d-md-block" v-else>
              <b-button type="button" variant="outline-dark" size="sm" class="btn-pill float-right" v-if="table.mostrarFiltros" @click="table.mostrarFiltros=false" v-b-tooltip.hover title="Ocultar filtros">
                <i class="fa fa-window-close"></i>
              </b-button>
            </b-col>
          </b-col>                    
        </b-row>
      </b-card>

      <b-card header-tag="header" footer-tag="footer">
        <b-row v-if="table.mostrarFiltros">
          <b-col md="2">
            <b-form-group label="Desde (vto)">            
              <b-form-input type="date" size="sm" v-model="filters.date_start" placeholder="Fecha Desde"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-form-group label="Hasta (vto)">            
              <b-form-input type="date" size="sm" v-model="filters.date_end" placeholder="Fecha Hasta"></b-form-input>
            </b-form-group>
          </b-col>          
          <b-col sm="2">
            <b-form-group label="Estado">
              <v-select :options="arr.filters.available" v-model="filters.available" placeholder="Todos" :multiple="false" :select-on-tab="true"></v-select>
            </b-form-group>
          </b-col>
          <b-col sm="4" class="mt-4">
            <b-button variant="outline-dark" size="sm" @click="load()" class="pull-right">
              <b-icon icon="filter" size="sm" class="mr-1" style="font-size:15px;"></b-icon>              
              Filtrar
            </b-button>
          </b-col>            
        </b-row>

        <b-row>
          <b-col>
            <b-table class="mb-0"
                    responsive="sm"
                    head-variant="dark"
                    :hover="true"
                    :small="true"
                    :fixed="true"
                    :items="table.items"
                    :fields="table.fields"
                    :filter="table.filter"
                    :current-page="table.currentPage"
                    :per-page="table.perPage"
                    v-if="table.items.length">                       
                  
                <template v-slot:table-colgroup="scope">
                  <col
                    v-for="field in scope.fields"                    
                    :key="field.key"
                    :style="{ width: field.width }"
                  >
                </template> 

                <template v-slot:cell(date)="data">
                  <div v-if="data.item.date">
                    {{ moment(data.item.date).format('DD MMMM YYYY') }}
                  </div>
                </template>

                <template v-slot:cell(days)="data">
                  <div v-if="data.item.date && data.item.date_expiration">
                    {{ moment(data.item.date_expiration).diff(moment(data.item.date), 'days') }}
                  </div>
                </template>

                <template v-slot:cell(date_expiration)="data">
                  <div v-if="data.item.date_expiration">
                    <b>{{ moment(data.item.date_expiration).format('DD MMMM YYYY') }}</b>
                  </div>
                </template>

                <template v-slot:cell(amount)="data">
                  <b>{{Intl.NumberFormat('es-AR',{style:'currency',currency: getCurrencyCode}).format(data.item.amount)}}</b>
                </template>

                <template v-slot:cell(transmitter)="data">
                  <span v-if="data.item.transmitter_name">
                    <b>Nombre:</b> {{data.item.transmitter_name}}
                  </span>
                  <span v-if="data.item.transmitter_documents_number">
                    | <b>CUIT</b> {{data.item.transmitter_documents_number}}
                  </span>
                </template>

                <template v-slot:cell(observations)="data">
                  <div v-if="data.item.observations" v-b-tooltip.hover :title="data.item.observations">
                    <b-icon icon="chat-square-text"></b-icon>
                  </div>                                      
                </template>

                <template v-slot:cell(nd)="data">
                  <b-badge v-if="data.item.nd">SI</b-badge>            
                </template>   

                <template v-slot:cell(f_action)="data">
                  <b-dropdown right text="Acción" size="sm" variant="outline-dark" class="pull-right">
                    <b-dropdown-item @click="edit(data.item)">
                      <i class="fa fa-pencil ml-0 mr-0" style="color:blue"></i> Editar
                    </b-dropdown-item>
                    <b-dropdown-item @click="remove(data.item)">
                      <i class="fa fa-trash mr-0 ml-0" style="color:red"></i> Eliminar
                    </b-dropdown-item>
                  </b-dropdown>
                </template>
            </b-table>
            <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
          </b-col>
        </b-row>
      </b-card>

      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
              <i class="fa fa-angle-double-left"></i>
              Volver
            </b-button>                        
          </b-col>

          <b-col>
            <nav>
              <b-pagination class="pull-right mb-0"
                            size="sm"
                            pills=""
                            :total-rows="getRowCount(table.items)"
                            :per-page="table.perPage"
                            v-model="table.currentPage" />
            </nav>
          </b-col>          
        </b-row>
      </b-card>

      <!-- ########################### -->
      <!-- #####     MODALES     ##### -->
      <!-- ########################### -->

      <!-- CRUD MAIN -->
      <b-modal v-model="modal.form.active"
              header-bg-variant="dark"
              header-text-variant="white">
        <div slot="modal-header">
          {{this.modal.form.title}}
        </div>

        <b-row>           
          <b-col md="6">
            <b-form-group label="Número">
              <b-form-input type="text"
                            size="sm"
                            v-model="crud.form.number"
                            required
                            placeholder="Ingresar el número">
              </b-form-input>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group label="Importe">
              <b-input-group prepend="$" size="sm">            
                <b-form-input v-model="crud.form.amount" 
                              type="number" 
                              setp="0.01" 
                              min="0.00"
                              :readonly="crud.form.id>0">
                </b-form-input>            
              </b-input-group>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group label="Fecha">
              <b-form-datepicker size="sm"  
                                 v-model="crud.form.date" 
                                 placeholder="Fecha" 
                                 local="es">
              </b-form-datepicker>                      
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group label="Vencimiento">
              <b-form-datepicker size="sm" 
                                 v-model="crud.form.date_expiration" 
                                 placeholder="Fecha Vencimiento" 
                                 local="es">
              </b-form-datepicker>                      
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group label="Banco">
              <b-form-input type="text"
                            size="sm"
                            v-model="crud.form.bank"
                            required
                            placeholder="Ingresar el banco">
              </b-form-input>
            </b-form-group>
          </b-col>
          
          <b-col md="6">
            <b-form-group label="Nombre Emisor">
              <b-form-input type="text"
                            size="sm"
                            v-model="crud.form.transmitter_name"
                            required
                            placeholder="Ingresar el nombre del emisor">
              </b-form-input>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group label="CUIT Emisor">
              <b-form-input type="text"
                            size="sm"
                            v-model="crud.form.transmitter_documents_number"
                            required
                            placeholder="Ingresar el CUIT del emisor">
              </b-form-input>
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group label="Observaciones">
              <b-form-textarea                
                v-model="crud.form.observations"
                placeholder="Ingresar las observaciones..."
                rows="3"
                max-rows="6"></b-form-textarea>
            </b-form-group>
          </b-col>  

          <b-col md="12" class="mb-3">       
            <b-form-group label="Tipo de Cheque" description="Si la opción se activa, los movimientos realizados, solo pueden utilizarse desde un punto de venta no declarado.">         
              <b-form-checkbox v-model="crud.form.nd" 
                                switch 
                                size="sm">
                ND
              </b-form-checkbox>                      
            </b-form-group>      
          </b-col>           
        </b-row>

        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.form.active=false">Cancelar</b-button>
          <b-button variant="dark" @click="save()">Guardar</b-button>          
        </div>
      </b-modal>
    </b-col>
  </b-row>
</template>
<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import Session from '@/handler/session'  
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper' 
  import moment from 'moment'

  export default {
    data: () => {
      return {      
        access: {
          module_id: Modules.FINANZAS,
          profile_id: Profiles.PERSONAL,
          view_reference: 'cheques',
          elements: {}
        },  
        table : {
          items: [],
          fields: [
            {key: 'id', label: 'ID', class: "align-middle text-center font-weight-bold", width:"3%"},
            {key: 'bank', label: 'Banco', class: "align-middle", width:"10%"},
            {key: 'number', label: 'Número', class: "align-middle text-center", width:"10%"},
            {key: 'date', label: 'Fecha', class: "align-middle text-center", width:"10%"},
            {key: 'date_expiration', label: 'Vencimiento', class: "align-middle text-center", width:"10%"},                                    
            {key: 'days', label: 'Días', class: "align-middle text-center", width:"5%"},            
            {key: 'transmitter', label: 'Emisor', class: "align-middle text-left", width:"27%"},
            {key: 'amount', label: 'Importe', class: "align-middle text-right", width:"10%"},
            {key: 'observations', label: '', class: "align-middle text-center", width:"5%"},                        
            {key: 'nd', label: 'ND', class:"align-middle", width:"5%"},
            {key: 'f_action', label:'', class: "align-middle", width:"5%"},
          ],
          filter: null,
          mostrarFiltros: true,
          currentPage: 1,
          perPage: 50,
        },      
        crud: {
          form: {
            id: 0,
            number: 0,
            date: '',
            date_expiration: '',
            amount: 0,
            bank: '',
            transmitter_name: '',
            transmitter_documents_number: '',
            observations: '',
            nd: false,
          },                 
        },
        modal: {
          form: {
            active: false,
            title: ''
          },        
        },
        arr: {
          filters : {
            available: [
              {code: true, label: 'En cartera'},
              {code: false, label: 'Entregado'}
            ]
          },                       
        },        
        filters: {
          date_start: '',
          date_end: '',
          available: null,
        },
      }
    },
    created () {     
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */
    },  
    mounted() {
      this.load()
    },
    computed: {
      getCurrencyCode() {
        return 'ARS'
      }
    },
    methods: {
      getRowCount (items) {
        return items.length
      },
      filterLoad() { 
        this.filters.date_start = moment().subtract(30, 'days').format('YYYY-MM-DD') 
        this.filters.date_end = moment().format('YYYY-MM-DD') 
      },
      load() {
        let loader = this.$loading.show()  
        
        var result = serviceAPI.filtrarCheques(this.filters, this.table.currentPage)        

        result.then((response) => {
          loader.hide()          
          this.table.items = response.data.data

          this.table.items.forEach(element => {            
            if(element.date_expiration < moment().add(7, 'days').format('YYYY-MM-DD') && element.available) {
              element._rowVariant = 'warning'  
            }
            if(element.date_expiration > moment().add(7, 'days').format('YYYY-MM-DD') && element.available) {
              element._rowVariant = 'success'  
            }

            if(!element.available) {
              element._rowVariant = 'danger'  
            }            
          });
        })
        .catch(error => {                         
          loader.hide()
          this.$awn.alert(Error.showError(error))
        });     
      },
      add() {
        this.crud.form.id = 0
        this.crud.form.number = 0
        this.crud.form.date = ''
        this.crud.form.date_expiration = ''
        this.crud.form.amount = 0
        this.crud.form.bank = ''
        this.crud.form.transmitter_name = ''
        this.crud.form.transmitter_documents_number = ''
        this.crud.form.observations = ''
        this.crud.form.nd = false

        this.modal.form.title = "Nuevo Cheque"
        this.modal.form.active = true
      },
      edit(item) {
        this.crud.form.id = item.id
        this.crud.form.number = item.number
        this.crud.form.date = item.date
        this.crud.form.date_expiration = item.date_expiration
        this.crud.form.amount = item.amount
        this.crud.form.bank = item.bank
        this.crud.form.transmitter_name = item.transmitter_name
        this.crud.form.transmitter_documents_number = item.transmitter_documents_number
        this.crud.form.observations = item.observations
        this.crud.form.nd = item.nd
        
        this.modal.form.title = "Editar Cheque"
        this.modal.form.active = true
      },
      remove(item) {
        this.crud.form.id = item.id
        this.crud.form.number = item.number 

        this.$bvModal.msgBoxConfirm('¿Desea borrar el item (' + this.crud.form.id + ') - '+ this.crud.form.number + '?', {
          title: 'Borrar Cheque',
          size: 'lg',
          buttonSize: 'lg',
          okVariant: 'danger',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'danger',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            let loader = this.$loading.show();
            var result = serviceAPI.eliminarCheque(this.crud.form.id);

            result.then((response) => {      
              this.modal.form.active = false        
              loader.hide()
              this.load()
              this.$awn.success("Registro eliminado");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })
      },
      save() {
        let loader = this.$loading.show();
        if (this.crud.form.id) {
          var result = serviceAPI.editarCheque(this.crud.form);
        } else {
          var result = serviceAPI.agregarCheque(this.crud.form);
        }

        result.then((response) => {
          this.modal.form.active = false
          loader.hide()
          this.load()
          this.$awn.success("Datos guardados con éxito");
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })
      },           
    }    
  }
</script>
<style>

</style>