const modules = {
	PERSONAL: 1,
  CLIENTES: 2,
  PROVEEDORES: 3,
  SENSORES: 4,
  NOTIFICACIONES: 5,
  PROYECTOS: 6,
  EMPLEADOS: 7,
  VENDEDORES: 8,
  BCRA: 9,
  PRODUCTOS: 10,
  INFORME_VISITAS: 11,
  INFORME_COBRANZAS: 12,
  INFORME_NOVEDADES: 13,
  INFORME_GENERAL: 14,
  PEDIDOS: 15,
  CONTENIDOS: 16,
  FORMULARIOS: 17,
  FLUJO_VENTAS: 18,
  GOOGLE_ADWORDS: 19,
  REDES_SOCIALES: 20,
  EMAIL_MARKETING: 21,
  ACCESOS: 22,
  PRECIOS: 23,
  BITLY: 24,
  WHATSAPP_MARKETING: 25,    
  VENTAS: 26,
  ORDENES_COMPRAS: 27,
  COMPRAS: 28,
  PRESUPUESTOS: 29,
  CONTABILIDAD: 30,
  FINANZAS: 31,
  CURSOS: 32,
  PAGOS: 33,
  SERVICIOS: 34,
  INFORME_SERVICIOS: 35,
  REPORTES: 36,
  INFORME_AUDITORIA: 37,
  INFORME_MARKETING: 38,  
  REMITOS: 39,
  ACTIVIDAD_LOG: 40,
  COSTOS: 41,
  RECIBOS_ERP: 42,
  PAGOS_ERP: 43,
  DEPOSITOS: 44,
  INSTANCIAS: 45,
  REMITOS_COMPRAS: 46,
  PHP: 47,
}

export default modules;
